.btn,
.btn-sm,
.btn-icon,
.btn-icon-round,
.btn-icon-round-white,
.btn-submit,
.btn-text {
  font-weight: 700;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn {
  display: inline-block;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border-width: 2px;
  border-radius: 9999px;
  border-color: #d0d0d0;
  color: #06a7ea;
  text-transform: uppercase;
  font-size: 0.75rem;
}

.btn:hover {
  border-color: #06a7ea;
}

.btn-sm {
  background-color: #06a7ea;
  border-radius: 9999px;
  padding: 0.5rem;
  color: #fff;
}

.btn-sm:hover {
  background-color: #022a3b;
}

.btn-icon-round,
.btn-icon-round-white {
  display: block;
  height: 2rem;
  width: 2rem;
  border-width: 2px;
  border-radius: 9999px;
  position: relative;
  font-size: 0.75rem;
}

.btn-icon-round span,
.btn-icon-round-white span {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
}

.btn-icon-round {
  border-color: #d0d0d0;
  color: #06a7ea;
}

.btn-icon-round:hover {
  background-color: #06a7ea;
  border-color: #06a7ea;
  color: #fff;
}

.btn-icon-round-white {
  border-color: #fff;
  color: #fff;
}

.btn-icon-round-white:hover {
  background-color: #fff;
  color: #022a3b;
}

.btn-submit {
  background-color: #06a7ea;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #fff;
  font-size: 0.75rem;
  text-transform: uppercase;
}

.btn-submit-disabled {
  background-color: #797979;
  pointer-events: none;
}

.btn-submit:hover {
  background-color: #022a3b;
}

.btn-text {
  font-size: 0.75rem;
  color: #06a7ea;
  text-transform: uppercase;
}

.h-300px { height: 300px;
}

.h-600px { height: 600px;
}

.min-w-180px { min-width: 180px;
}

.min-w-320px { min-width: 320px;
}

.max-w-460px { max-width: 460px;
}

.-mb-1px { margin-bottom: -1px;
}

.-mb-2px { margin-bottom: -2px;
}

.w-128px { width: 128px;
}

.h-128px { height: 128px;
}

.text-xxs {
    font-size: 0.625rem;
  }
  
  .text-xs {
    font-size: 0.75rem;
  }
  
  .text-sm {
    font-size: 0.875rem;
  }
  
  .text-base {
    font-size: 1rem;
  }
  
  .text-lg {
    font-size: 1.125rem;
  }
  
  .text-xl {
    font-size: 1.25rem;
  }
  
  .text-2xl {
    font-size: 1.5rem;
  }
  
  .text-3xl {
    font-size: 1.875rem;
  }
  
  .text-4xl {
    font-size: 2.25rem;
  }
  
  .text-5xl {
    font-size: 3rem;
  }
  
  .text-6xl {
    font-size: 4rem;
  }

  /* custom scroll bar */
  /* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}