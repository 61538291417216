.btn,
.btn-sm,
.btn-icon,
.btn-icon-round,
.btn-icon-round-white,
.btn-submit,
.btn-text {
  font-weight: 700;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-sm {
  background-color: #06a7ea;
  border-radius: 9999px;
  padding: 0.5rem;
  color: #fff;
}

.suggestion-list ul li:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.btn-sm:hover {
  background-color: #022a3b;
}

.pb-1 {
  padding-bottom: 10px;
}

.tabs {
  display: -webkit-box;
  display: flex;
  border-bottom-width: 1px;
  border-color: #d0d0d0;
  font-weight: 700;
  font-size: 0.75rem;
  color: #d0d0d0;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-bottom: 0.75rem;
}

.tabs-item {
  border-bottom-width: 3px;
  border-color: transparent;
  margin-bottom: -2px;
  cursor: pointer;
}

.tabs-item.active {
  border-color: #06a7ea;
  color: #1f1f1f;
}

.tabs-item p {
  display: block;
  padding: 0.25rem 0.75rem;
}

.white-bg {
  background-color: #ffffff;
}

.auto-complete-styles {
  position: relative;
  height: 41px;
  z-index: 9;
}

.logo-color {
  color: #233591;
}

.fixed-height {
  height: 100% !important;
}

.bullet-style li {
  list-style-type: disc;
}

.adp-legal {
  display: none;
}

.star-ratings {
  margin: 0px -0.25rem;
}

#mCSB_1 {
  width: 100% !important;
}

.mCSB_inside > .mCSB_container {
  margin-right: 12px !important;
}

/* .mCS-rounded-dark-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
  background-color: red!important; 
  width: 20px!important; 
  height: 20px!important; 
} */
